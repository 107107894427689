//use axios to get the location of the user posting on https://back.nicogab.com/instructions

import axios from "axios";

export type Direction =
  | "S"
  | "W"
  | "NNE"
  | "NE"
  | "ENE"
  | "E"
  | "ESE"
  | "SE"
  | "SSE"
  | "SSW"
  | "SW"
  | "WSW"
  | "WNW"
  | "NW"
  | "NNW"
  | "N";
export type Instructions = {
  direction: Direction;
  distance: number;
};

export type Position = {
  latitude: number;
  longitude: number;
};

export class LocationGateway {
  async getInstructions(position: Position): Promise<Instructions> {
    const response = await axios.post<Instructions>(
      `${
        window.location.toString().includes("nicogab.com")
          ? "https://back.nicogab.com"
          : "http://localhost:3001"
      }/instructions`,
      position
    );
    return response.data;
  }
}
