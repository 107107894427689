import React, { createContext, useContext } from "react";
import {
  Instructions,
  LocationGateway,
  Position,
} from "../gateways/location.gateway";

type GeolocationContextProps = {
  getInstructions: (position: Position) => Promise<Instructions>;
};

const GeolocationContext = createContext<GeolocationContextProps>({
  getInstructions: async () => {
    return { direction: "N", distance: 0 };
  },
});

type GeolocationProviderProps = {
  children: React.ReactElement;
};

export const GeolocationProvider = ({ children }: GeolocationProviderProps) => {
  const gateway = new LocationGateway();
  return (
    <GeolocationContext.Provider
      value={{
        getInstructions: gateway.getInstructions,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocationContext = (): GeolocationContextProps =>
  useContext(GeolocationContext);

// Usage
// const { numberSelected, setNumberSelected } = useNumberValue();
