import "./App.css";

import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

//import TreasureHunt from "./pages/treasure-hunt/TreasureHunt";
import { GeolocationProvider } from "./context/GeolocationContext";
import { QueryClient, QueryClientProvider } from "react-query";
import Location from "./pages/location/Location";

function Layout() {
  const queryClient = new QueryClient();

  return (
    <div>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <GeolocationProvider>
            <div className="w-screen h-dvh">
              <Outlet />
            </div>
          </GeolocationProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </div>
  );
}

/**
 * App is the root React component.
 */
const App: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Location />} />
        <Route path="location" element={<Location />} />
      </Route>
    </Routes>
  );
};

export default App;
